import Vue from "vue";
import VueRouter from "vue-router";
import Cookies from "js-cookie";
import store from "@/store/index";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/home",
      name: "home",
      component: () => import("@/views/Home.vue"),
    },
    {
      path: "/forgot-password",
      name: "forgot-password",
      component: () => import("@/views/forgot_password.vue"),
    },
    {
      path: "/conditions",
      name: "conditions",
      component: () => import("@/views/conditions.vue"),
    },
    {
      path: "/events",
      name: "events",
      component: () => import("@/views/events.vue"),
    },
    {
      path: "/devices",
      name: "devices",
      component: () => import("@/views/devices.vue"),

    },
    {
      path: "/devices/add",
      name: "add_device",
      component: () => import("@/views/add_device.vue"),
    },
    {
      path: "/devices/edit-device",
      name: "edit-device",
      component: () => import("@/views/edit_device.vue"),
    },
    {
      path: "/devices/view-datas",
      name: "view-datas",
      component: () => import("@/views/view_datas.vue"),
    },
    {
      path: "/devices/view-device",
      name: "view-device",
      component: () => import("@/views/view_device.vue"),
    },
    {
      path: "/dashboards",
      name: "dashboards",
      component: () => import("@/views/view_dashboard.vue"),    
      meta: {
        layout: "full",
      },
    },
    {
      path: "/reports_table",
      name: "reports_table",
      component: () => import("@/views/Reports.vue"),
    },
    {
      path: "/map",
      name: "map",
      component: () => import("@/views/onlyMap.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/users",
      name: "users",
      component: () => import("@/views/users.vue"), 
    },
    {
      path: "/edit-profile",
      name: "edit-profile",
    },
    {
      path: "/edit-user-group",
      name: "edit-user-group",
      component: () => import("@/views/edit_member.vue"),
    },
    {
      path: "/view-groups",
      name: "view-groups",
      component: () => import("@/views/view_groups.vue"),
    },
    {
      path: "/add-group",
      name: "add-group",
      component: () => import("@/views/add_group.vue"),
    },
    {
      path: "/",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/reset-password",
      name: "reset-password",
      component: () => import("@/views/email/reset_password.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/confirm-user",
      name: "confirm-user",
      component: () => import("@/views/email/confirm_user.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/error-401",
      name: "error-401",
      component: () => import("@/views/error/Error401.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

router.beforeEach((to, from, next) => {
  if (to.name !== "login" && store.state.token_api == "") {
    if (Cookies.get("token_api") != null) {
      store.state.token_api = Cookies.get("token_api");
      next();
    } else {
      next({ name: "login" });
    }
  } else next();
});

export default router;
