import Vue from "vue";
import Vuex from "vuex";

// Modules
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";

Vue.use(Vuex);

export default new Vuex.Store({
  state: { refresh_interval: '', selected_condition: [], map_lines: '', token_api: "", active_group: { name: "Selecione o grupo", id: -1 }, selected_device: '0', selected_group: {name: "", description:"", id:""}, selected_member: -1, group_exist: [], selected_widget: '', wall_points: [],},
  modules: {
    app,
    appConfig,
    verticalMenu,
  },
  strict: process.env.DEV,
});
